.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    width: 25rem;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    z-index: 1000;
    background: var(--navy); 
    padding: 2rem;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar::-webkit-scrollbar{
    width: 0.8rem;
}

.sidebar::-webkit-scrollbar-thumb{
    border-radius: 1rem;
    background-color: var(--dark-blue);
}

.sidebar::-webkit-scrollbar-track{
    background: transparent;
}

.sidebar .menu-btn{
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: var(--white);
    font-size: 3rem;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    display: none;
}

.sidebar .logo{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
       align-items: center;
    position: relative;
}

.sidebar .logo h2{
    color: var(--white);
    font-size: 4rem;
    font-weight: 400;
    letter-spacing: 0.2rem;
    font-family: 'Dancing Script', cursive;
}

.sidebar .logo h2 a{
    color: #FFFFFF;
    text-shadow: 4px 3px 0 #7A7A7A;
    font-family: inherit;
}

.sidebar .navbar{
    padding: 4rem 0;
}

.sidebar .navbar li{
    list-style-type: none;
}

.sidebar .navbar li a{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
       align-items: center;
    gap: 1rem;
    height: 100%;
    width: 100%;
    font-size: 1.8rem;
    color: var(--white);
    margin: 0.5rem 0;
    padding: 0.7rem 1.5rem;
    position: relative;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar .navbar li a:before{
    content: "";
    position: absolute;
    left: 1.2rem;
    bottom: 1.2rem;
    height: 1.2rem;
    width: 1.2rem;
    border: 0.3rem solid var(--dark-blue);
    border-width: 0 0 0.3rem 0.3rem;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
}

.sidebar .navbar li a::after{
    content: "";
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    height: 1.2rem;
    width: 1.2rem;
    border: 0.3rem solid var(--dark-blue);
    border-width: 0.3rem 0.3rem 0 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0; 
}

.sidebar .navbar li a span{
    margin-left: 1rem;
    font-size: 2rem;
}

.sidebar .navbar li a:hover,
.sidebar .navbar li.active a{ 
    background-color: var(--dark-blue) ;
}

.sidebar .navbar li a:hover::before{
    left: -0.5rem;
    bottom: -0.5rem;
    opacity: 1;
}

.sidebar .navbar li  a:hover::after{
    right: -0.5rem;
    top: -0.5rem;
    opacity: 1;
}

.sidebar .navbar li a .icon{
    color: var(--white);
    height: 2.5rem;
    min-width: 2.5rem;
    font-size: 2.5rem;
    text-align: center;
}

.sidebar .social{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 2rem;
}

.sidebar .social a{
    color: var(--white);
    font-size: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
       align-items: center;
}

@media (max-width: 768px){

    body{
        padding-left: 7rem;
    }

    .sidebar{
        width: 7rem;
        padding: 1.5rem;
    }

    .sidebar.active{
        width: 25rem; 
    }

    .sidebar .menu-btn{
        display: block;
    }

    .sidebar.active .menu-btn{
        text-align: right; 
    }

    .sidebar .logo{
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .sidebar .logo h2,
    .sidebar .social{
        opacity: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .sidebar.active .logo h2,
    .sidebar.active .social{
        opacity: 1;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .sidebar .navbar li a{
        padding: 0.6rem;
    }

    .sidebar .navbar li a span{
        opacity: 0;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
        pointer-events: none;
    }

    .sidebar.active .navbar li a span{
        opacity: 1;
        pointer-events: auto;
    };

}